<template>
  <div v-if="selectedProducto">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
    >
      <productosForm
        productoSubmit="Editar Producto"
        :producto="selectedProducto"
        @processProducto="updateProducto"
      />
    </b-overlay>
  </div>
</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const productosForm = () => import('@/layouts/components/Productos/ProductosForm.vue')

export default {
  components: {
    BOverlay,
    productosForm,
  },
  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    ...mapState('productos', ['selectedProducto']),
  },
  methods: {
    ...mapActions({ editarproductos: 'productos/updateProductos' }),
    updateProducto(producto) {
      this.spinner = true
      this.editarproductos(producto).then(() => {
        const errorproductos = store.state.productos

        // const errorMessage = errorproductos.errorMessage.errors
        if (!errorproductos.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Producto editado con éxito 👍',
              text: `El producto "${producto.codigo} - ${producto.nombre}" fue editado con éxito!`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            timeout: 4000,
          })
          this.$router.replace({
            name: 'productos',
          })
        } else if (errorproductos.error) {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulentoaaaa!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
